.navbar {
    background: rgba(0, 0, 0, 0.2);
    width: max-content;
    /* display: block; */
    padding: 0.7rem 1.7rem;
    z-index: 2;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 0.8rem;
    border-radius: 3rem;
    backdrop-filter: blur(15px);
    border: 1px solid var(--color-primary);
    margin-top: 1rem;
}

.navbarSmall {
    background: rgba(0, 0, 0, 0.2);
    width: max-content;
    /* display: block; */
    padding: 0.7rem 1.7rem;
    z-index: 2;
    position: fixed;
    top: 90%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 1rem;
    border-radius: 3rem;
    backdrop-filter: blur(15px);
    border: 1px solid var(--color-primary);
    /* border: 1px solid red; */
}

.navbar a {
    background: transparent;
    padding: 0.9rem;
    border-radius: 50%;
    display: flex;
    color: var(--color-light);
    font-size: 1.1rem;
}

.navbar a:hover {
    background: rgba(0, 0, 0, 0.3);
}

.navbar a.active {
    background: var(--color-primary);
    color: var(--color-bg);
}

@media screen and (max-width: 900px) {
    .navbar {
        bottom: 10%;
    }
}