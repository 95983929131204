.portfolio__conatiner {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    /* border: 1px solid red; */
}

.portfolio__item {
    /* display: flex;
    flex-direction: column;
    align-items: center; */
    background: var(--color-bg-variant);
    border-radius: 2rem;
    padding: 1.2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.portfolio__item:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
}

.portfolio__item-image {
    border-radius: 1.5rem;
    overflow: hidden;
}

.portfolio__item-image img {
    width: 350px;
}

.portfolio__item h3 {
    margin: 1.2rem 0 2rem;
}

.portfolio__item-cta {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

.videoCont {
    /* border: 1px solid red; */
    border-radius: 1rem;
    padding: 1rem 0rem;
    display: flex;
    width: 100%;
    margin: 0.5rem 0rem;
}


/* media queries for small devices  */

@media screen and (max-width: 1024px) {
    .portfolio__conatiner {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}


/* media queries for small devices  */

@media screen and (max-width: 600px) {
    .portfolio__conatiner {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
    .portfolio__item {
        max-width: 100%;
    }
    .portfolio__item-image img {
        width: 100%;
    }
}