.services__container {
  display: grid;
  /* grid-template-columns: repeat(3, 1fr); uncomment this if having more than on service to provide */
  grid-template-columns: 1fr;
  gap: 3rem;
  place-items: center;
}

.services {
  background: var(--color-bg-variant);
  border-radius: 0 0 2rem 2rem;
  border: 1px solid var(--color-primary);
  height: fit-content;
  transition: var(--transition);
}

.services:hover {
  background: transparent;
  border-color: var(--color-bg-variant);
  cursor: default;
}

.service__head {
  background: var(--color-primary);
  padding: 2rem;
  border-radius: 0 0 2rem 2rem;
  box-shadow: 0 2rem 1rem rgba(0, 0, 0, 0.1);
}

.service__head h3 {
  color: var(--color-bg);
  font-size: 1rem;
  text-align: center;
}

.service__list {
  padding: 2rem;
}

.service__list li {
  display: flex;
  gap: 1rem;
  margin-bottom: 0.8rem;
}

.service__list-icons {
  color: var(--color-primary);
  margin-top: 2px;
}

.service__list p {
  font-size: 0.9rem;
}

/* media queries for small devices  */

@media screen and (max-width: 1024px) {
  .services__container {
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    justify-content: center;
    align-items: center;
  }
  .services {
    height: auto;
  }
}

/* media queries for small devices  */

@media screen and (max-width: 600px) {
  .services__container {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
}

/* media queries for medium devices  */

@media screen and (max-width: 900px) {
  .services__container {
    grid-template-columns: 1fr;
    justify-content: center;
    gap: 1.5rem;
  }
}
