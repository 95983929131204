.about__container {
    display: grid;
    grid-template-columns: 35% 50%;
    gap: 15%;
    place-items: center;
}

.about__me {
    width: 90%;
    /* height: 87%; */
    /* height: fit-content; */
    aspect-ratio: 1/1;
    border-radius: 2rem;
    background: linear-gradient( 45deg, transparent, var(--color-primary), transparent);
    display: grid;
    place-items: center;
    /* border: 2px solid red; */
}

.about__me-image {
    border-radius: 2rem;
    width: 99%;
    overflow: hidden;
    transform: rotate(10deg);
    transition: var(--transition);
}

.about__me-image img {
    margin-bottom: -0.5rem;
}

.about__me-image:hover {
    transform: rotate(0deg);
}

.about__cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
    margin-top: 2rem;
}

.about__card {
    background: var(--color-bg-variant);
    border: 1px solid transparent;
    border-radius: 1rem;
    padding: 2rem;
    text-align: center;
    transition: var(--transition);
}

.about__card:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.about__card .about__icon {
    color: var(--color-primary);
    font-size: 1.4rem;
    margin-bottom: 1rem;
}

.about__card h5 {
    font-size: 0.95rem;
}

.about__card small {
    font-size: 0.7rem;
    color: var(--color-light);
}

.about__content {
    margin: 2rem 0 2.6rem;
    color: var(--color-light);
}

.about__content p {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.background {
    /* border: 2px solid red; */
    margin-bottom: 3rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.background h4 {
    font-size: 1.3rem;
}

.background h5 {
    color: var(--color-light);
    text-align: center;
    font-size: 1rem;
}

.coderepo {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2.2rem;
    margin-bottom: 2rem;
}

.coderepo .coderepoanchor {
    display: flex;
    align-items: center;
}

.coderepo a {
    font-size: 1.3rem;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.7rem;
    padding: 0 1rem;
    background-color: var(--color-bg-variant);
    border-radius: 0.4rem;
}

.coderepo a:hover {
    background: transparent;
    border: 1px solid;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.coderepo .iconsnew {
    color: white;
    font-size: 1.4rem;
}

.coderepo .iconsnew:hover {
    color: var(--color-primary);
}


/* media queries for small devices  */

@media screen and (max-width: 1024px) {
    .about__container {
        grid-template-columns: 1fr;
        gap: 0;
    }
    .about__me {
        width: 50%;
        margin: 2rem auto 4rem;
    }
    .about__content p {
        margin: 1rem 0 1.5rem;
    }
}


/* media queries for small devices  */

@media screen and (max-width: 600px) {
    .about__me {
        width: 18rem;
        /* width: 75%; */
        margin: 0 auto 1.5rem;
    }
    .about__me-image {
        /* border: 2px solid white; */
        width: 18rem;
    }
    .about__me-image img {
        margin-bottom: -0.5rem;
    }
    .about__cards {
        /* grid-template-columns: 1fr fr; when there is experience and clients at that time I have to uncomment this because at that time I want them to take half width */
        grid-template-columns: 1fr;
        gap: 1rem;
        place-items: center;
        margin-top: 1.5rem;
    }
    /* erase .about_card when there is achievement and clients */
    .about__card {
        width: 75%;
    }
    .about__content {
        text-align: center;
    }
    .about__content p {
        margin: 1.5rem 0;
    }
    .background {
        /* border: 2px solid red; */
        margin-bottom: 2.5rem;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
    }
    .background h5 {
        color: var(--color-light);
        text-align: center;
    }
    .about__content {
        margin: 1rem 0 1.3rem;
        /* margin: 1rem 0 1.3rem; */
        color: var(--color-light);
        /* border: 1px solid red; */
    }
    .about__content p {
        padding-top: 0rem;
        padding-bottom: 0rem;
    }
    .coderepo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1.5rem;
        margin-bottom: 1.5rem;
    }
    .coderepo .coderepoanchor {
        display: flex;
        align-items: center;
    }
    .coderepo a {
        font-size: 1.1rem;
        font-weight: 400;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.7rem;
        padding: 0 1rem;
        background-color: var(--color-bg-variant);
        border-radius: 0.4rem;
    }
    .coderepo a:hover {
        background: transparent;
        border: 1px solid;
        border-color: var(--color-primary-variant);
        cursor: default;
    }
    .coderepo .iconsnew {
        color: white;
        font-size: 1.2rem;
    }
    .coderepo .iconsnew:hover {
        color: var(--color-primary);
    }
}