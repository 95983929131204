.theme {
    position: absolute;
    display: flex;
    justify-content: center;
    width: fit-content;
    top: 0;
    right: 0;
    margin-top: 0.5rem;
    margin-right: 0.5rem;
}

.smallScreenTheme {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    top: 0;
    right: 0;
    margin-top: 0.3rem;
    margin-right: 0.3rem;
    border: 0.5px solid var(--color-primary);
    border-radius: 5px;
    padding: 3px 5px;
}

.themeSelected {
    position: absolute;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
    /* width: fit-content; */
    top: 0;
    right: 0;
    margin-top: 5rem;
    margin-right: 1.7rem;
    border: 1px solid var(--color-primary);
    padding: 20px 7px;
    border-radius: 15px;
    background: rgba(0, 0, 0, 0.1);
    /* background: #14213d; */
    backdrop-filter: blur(5px);
}


/* .smallScreenTheme:hover {
    background-color: white;
} */

.themeSelectedSmall {
    position: absolute;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
    /* width: fit-content; */
    top: 0;
    right: 0;
    margin-top: 2.7rem;
    margin-right: 0.3rem;
    border: 1px solid var(--color-primary);
    /* border: 1px solid red; */
    padding: 10px 3px;
    border-radius: 15px;
    background: rgba(0, 0, 0, 0.1);
    /* background: #14213d; */
    backdrop-filter: blur(5px);
}


/* .themeSelected .themeBtn {
    border: 1px solid var(--color-primary);
    background-color: transparent;
} */

.themeHided {
    display: none;
}

.colorCombination {
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid yellow; */
    /* border-bottom: 1px solid #13ca91; */
    justify-content: space-between;
    padding: 5px;
    gap: 10px;
}

.colorCombinationSmall {
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid rgb(0, 0, 255); */
    /* border-bottom: 1px solid #13ca91; */
    justify-content: space-between;
    padding: 5px;
    gap: 10px;
}

.colorCombination:hover {
    cursor: pointer;
}

.colorCombinationSmall:hover {
    cursor: pointer;
}

.themeSelected .colorCombination .iconDesignDefault {
    color: #4db5ff;
}

.themeSelected .colorCombination .iconDesign1 {
    color: #13ca91;
}

.themeSelected .colorCombination .iconDesign2 {
    color: #b537f2;
}

.themeSelected .colorCombination .iconDesign3 {
    color: #ff2079;
}

.themeSelected .colorCombination .iconDesign4 {
    color: #f5d300;
}

.themeSelectedSmall .colorCombinationSmall .iconDesignDefaultSmall {
    color: #4db5ff;
    font-size: 1.3rem;
}

.themeSelectedSmall .colorCombinationSmall .iconDesign1Small {
    color: #13ca91;
    font-size: 1.3rem;
}

.themeSelectedSmall .colorCombinationSmall .iconDesign2Small {
    color: #b537f2;
    font-size: 1.3rem;
}

.themeSelectedSmall .colorCombinationSmall .iconDesign3Small {
    color: #ff2079;
    font-size: 1.3rem;
}

.themeSelectedSmall .colorCombinationSmall .iconDesign4Small {
    color: #f5d300;
    font-size: 1.3rem;
}

.themeSelected .colorCombination .colorName {
    margin-right: auto;
    font-size: 0.7rem;
}

.themeSelectedSmall .colorCombinationSmall .colorNameSmall {
    display: none;
    /* border: 1px solid green; */
}